/* eslint-disable no-undef */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import axiosRequest from './../../utils/axiosRequest';
// import axiosLocal from './../../utils/axiosLocal';
import { generatePass } from './../../utils/generatePass';
import { addAccessId, addAccessProjectId } from './kanban';
import { createUser } from './user';
import { canAccessIsSetter } from './oldtask';
import { displayNameSplite } from './../../utils/displayNameSplit';
import { auth } from '../../contexts/FirebaseContext';
import { MAIL_CONFIG, mail } from 'src/config';
// ----------------------------------------------------------------------

const initialUser = {
  lastName: '',
  firstName: '',
  email: '',
  photoUrl: '',
  poste: '',
  phoneNumber: '',
  password: '',
  confirmation: '',
  delete: false,
  active: true,
  status: null,
  responsables: [],
  roles: {
    isGuest: true,
    admin: false,
    task: {
      read: true,
      create: false,
      edit: false,
      delete: false
    },
    opportunity: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    audience: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    stage: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    contact: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    users: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    news: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    chat: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    document: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    client: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    manage_persons: {
      read: false,
      create: false,
      edit: false,
      delete: false
    },
    oldTask: {
      read: false,
      create: false,
      edit: false,
      delete: false
    }
  }
};

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  mails: { byId: {}, allIds: [] },
  labels: []
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;

      state.isLoading = false;
      state.mails.byId = objFromArray(mails);
      state.mails.allIds = Object.keys(state.mails.byId);
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload;

      state.mails.byId[mail.id] = mail;
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },

    //SEND MAIL
    sendMailSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLabels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/labels');
      dispatch(slice.actions.getLabelsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMails(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mails', { params });
      dispatch(slice.actions.getMailsSuccess(response.data.mails));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mail', {
        params: { mailId }
      });
      dispatch(slice.actions.getMailSuccess(response.data.mail));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendMail({ to, from, subject, template }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosRequest.post('/mail/send', { to, from, subject, html: template });
      dispatch(slice.actions.sendMailSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      //console.log(error?.response);
    }
  };
}

export function setToAffectTask(affectations, guest, projectTitle, task, projectKey, taskId, callBack = null) {
  return async (dispatch) => {
    const affect = [...affectations];
    const affectIds = affect.map((af) => af.id);
    const _guest = [...guest];
    const taskName = task?.name || '';

    try {
      const domaine = window.location.host;

      const subject = MAIL_CONFIG.from;

      const from = MAIL_CONFIG.from;
      const accessLink = `https://${domaine}/dashboard/task/${projectKey}/${taskId}`;

      dispatch(addAccessId(affectIds, projectKey, taskId));

      const description = `${auth.currentUser.displayName} vous a assigné à la tâche  ${taskName} du projet ${projectTitle}.`;

      affect.map(async (aff) => {
        const data = {
          salutation: 'BONJOUR',
          name: aff?.name || aff?.displayName,
          description: description,
          link: accessLink,
          subject: subject,
          header: 'Affectation de tâche'
        };

        await axiosRequest.post('/mail/template', {
          to: aff.email,
          from,
          templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
          data
        });
      });

      _guest.map(async (pers) => {
        const pass = generatePass();
        const nameTab = pers.name?.split(' ');

        const user = {
          ...initialUser,
          email: pers.email,
          lastName: nameTab[0],
          firstName: nameTab[1],
          photoUrl: pers?.photoUrl || '',
          password: pass
        };

        const data = {
          password: pass,
          email: pers.email,
          salutation: 'BONJOUR',
          name: pers?.name || pers?.displayName,
          description: description,
          link: accessLink,
          subject: subject,
          header: 'Invitation'
        };

        dispatch(createUser({ user: user, callback: (id) => dispatch(addAccessId([id], projectKey, taskId)) }));
        await axiosRequest.post('/mail/template', {
          to: pers.email,
          from,
          templateId: process.env.REACT_APP_EMAIL_INVITATION_TEMPLATE,
          data
        });
      });

      if (callBack) {
        callBack();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      //console.log(error?.response);
    }
  };
}

export function setToAffectOldTask(newPartenaire, desc, numTask, oldTaskId, callback = null) {
  return async (dispatch) => {
    try {
      const domaine = window.location.host;
      const accessLink = `https://${domaine}/dashboard/oldtask`;

      const subject = MAIL_CONFIG.from;

      const from = MAIL_CONFIG.from;

      const description = `Bienvenue sur ${subject} Management. 
                            Vous êtes invité à rejoindre le projet  ${desc} - N° ${numTask}. 
                            Ci-dessous vous verrez vos accès pour notre plateforme. 
                            Merci, et à bientôt.
                            `;

      newPartenaire.map(async (part) => {
        const pass = generatePass();
        const nameTab = displayNameSplite(part.society);

        const user = {
          ...initialUser,
          roles: {
            ...initialUser.roles,
            task: {
              ...initialUser.roles.task,
              read: false
            },
            oldTask: {
              ...initialUser.roles.oldTask,
              read: true
            }
          },
          email: part.email,
          lastName: nameTab?.lastName || '',
          firstName: nameTab?.firstName || '',
          photoUrl: '',
          password: pass
        };

        const data = {
          password: pass,
          email: part.email,
          salutation: 'BONJOUR',
          name: part?.society || part?.email,
          description: description,
          link: accessLink,
          subject: subject,
          header: 'Invitation'
        };

        dispatch(createUser({ user: user, callback: (id) => dispatch(canAccessIsSetter([id], oldTaskId)) }));
        await axiosRequest.post('/mail/template', {
          to: part.email,
          from,
          templateId: process.env.REACT_APP_EMAIL_INVITATION_TEMPLATE,
          data
        });
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      //console.log(error?.response);
    }
  };
}

export function setToAffectToProject(newPartenaire, projectName, projectId, callback = null) {
  return async (dispatch) => {
    try {
      const domaine = window.location.host;
      const accessLink = `https://${domaine}/dashboard/task/${projectId}`;

      const currentUser = auth.currentUser;

      const subject = MAIL_CONFIG.from;

      const from = MAIL_CONFIG.from;

      const description = `Bienvenue sur ${subject} Management. 
                            ${currentUser?.displayName} Vous êtes invité à rejoindre le projet  ${projectName}.`;

      newPartenaire.map(async (part) => {
        const pass = generatePass();

        const user = {
          ...initialUser,
          roles: {
            ...initialUser.roles,
            task: {
              ...initialUser.roles.task,
              read: true
            },
            oldTask: {
              ...initialUser.roles.oldTask,
              read: false
            }
          },
          email: part.email,
          lastName: part?.lastName || '',
          firstName: part?.firstName || '',
          photoUrl: '',
          password: pass
        };

        const data = {
          password: pass,
          email: part.email,
          salutation: 'BONJOUR',
          name: part?.name || part?.email,
          description: description,
          link: accessLink,
          subject: subject,
          header: 'Invitation'
        };

        dispatch(createUser({ user: user, callback: (id) => dispatch(addAccessProjectId([id], projectId)) }));
        await axiosRequest.post('/mail/template', {
          to: part.email,
          from,
          templateId: process.env.REACT_APP_EMAIL_INVITATION_TEMPLATE,
          data
        });
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      //console.log(error?.response);
    }
  };
}

/**
 *
 * @param email
 * @param templateId
 * @param from
 * @param data {{description:string, name:string, salutation:string, link:string, header:string}}
 * @param onResolve {function}
 * @param onReject {function}
 * @returns {Promise<void>}
 */
export const emitMail = ({
  email,
  templateId = mail.template.members,
  from = mail.from,
  data,
  onResolve,
  onReject
}) => {
  return async () => {
    try {
      const mailData = {
        to: email,
        templateId,
        from,
        data: {
          link: data?.link || '#',
          subject: mail.subject,
          salutation: data?.salutation || '',
          ...data
        }
      };
      await axiosRequest.post('/mail/template', mailData);

      onResolve && onResolve();
    } catch (e) {
      //console.log(e);
      // console.trace()
      onReject && onReject(e);
    }
  };
};
