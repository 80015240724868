
/**
 * Extracts the text content from an HTML string.
 * 
 * Creates a temporary div element, sets its innerHTML to the provided HTML string, 
 * then returns the textContent property, trimming whitespace.
 * 
 * @param {string} chaineHTML - The HTML string to extract text from.
 * @returns {string} The extracted text content.
 */
export default function extraireTexteHTML(chaineHTML) {
  var tempDiv = document.createElement('div');
  tempDiv.innerHTML = chaineHTML;
  var texte = tempDiv.textContent || tempDiv.innerText || "";
  return texte.trim();
}
