import { useEffect, useMemo, useState } from 'react';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getBoard, getBoardWithSub } from 'src/redux/slices/kanban';
import { useDispatch, useSelector } from 'src/redux/store';
import { useOneProject } from './useProject';

import { get, keys } from 'lodash';
import { difference } from '../utils/difference';
import useAuth from 'src/hooks/useAuth';
import { TASK_VISIBILITY } from 'src/constants';

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  cards: {},
  columns: {},
  columnOrder: [],
  cardsOnArray: [],
  columnsOnArray: [],
  columnOrderId: null
};

/**
 *
 * @returns  {{
 * board: {cards:{}, cardsOnArray: [], columnsOnArray: [], columns: {}, columnOrder: [], columnOrderId: null}
 * loading: Boolean
 * }}
 */
export default function useTaskProject({ projectId, getSub = false }) {
  const { user } = useAuth();

  const { project: currentProject } = useOneProject({ id: projectId });

  const [loading, setLoading] = useState(true);
  const [board, setBoard] = useState(initialState);

  const kanban = useSelector((state) => state.kanban);

  const gettasks = useSelector((state) => state.firestore.ordered['_tasks']);
  const getsubtasks = useSelector((state) => state.firestore.ordered['_subtasks']);
  const gettaskColumns = useSelector((state) => state.firestore.ordered['_taskColumns']);
  const getcolumnOrder = useSelector((state) => state.firestore.ordered['_columnOrder']);
  const currentprojectsManagers = currentProject?.managers || [];
  //add managers to cards in gettaskboard
  // let getTaskBoard = useSelector((state) => state.kanban.board);
  const getTaskBoard = useSelector((state) => {
    // map all mamangers to all  and replace managers with current project managers
    let _board = state.kanban.board;
    let cards = _board?.cards;
    let cardsOnArray = _board?.cardsOnArray;

    let newCards = {};
    let newCardsOnArray = [];

    keys(cards)?.forEach((key) => {
      let card = cards[key];
      let mamangers = card?.mamangers || [];
      let newManagers = [...mamangers, ...currentprojectsManagers];
      newCards[key] = { ...card, mamangers: newManagers };
    });

    cardsOnArray?.forEach((card) => {
      let mamangers = card?.mamangers || [];
      let newManagers = [...mamangers, ...currentprojectsManagers];
      newCardsOnArray.push({ ...card, mamangers: newManagers });
    });

    return { ..._board, cards: newCards || {}, cardsOnArray: newCardsOnArray || [] };
  });

  const tasks = useMemo(() => {
    const isManager = Boolean((currentProject?.managers || [])?.find((el) => el?.id === user?.id));

    const _list = isEmpty(gettasks) ? [] : gettasks;
    const sublist = isEmpty(getsubtasks) ? [] : getsubtasks;

    const list = [..._list, ...sublist];

    return list
      ?.map((el) => ({ ...el, _visibility: el?.visibility || TASK_VISIBILITY.PUBLIC }))
      ?.filter((el) => isManager || el?._visibility === TASK_VISIBILITY.PUBLIC || el?.canAccessId?.includes(user?.id))
      ?.map(({ _visibility, ...rest }) => ({ ...rest }));
  }, [gettasks, getsubtasks, currentProject, user?.id]);

  const taskColumns = useMemo(() => {
    return isEmpty(gettaskColumns) ? [] : gettaskColumns;
  }, [gettaskColumns]);

  const columnOrder = useMemo(() => {
    return isEmpty(getcolumnOrder) ? [] : getcolumnOrder;
  }, [getcolumnOrder]);

  const dispatch = useDispatch();

  const queryFilter = useMemo(() => {
    if (getSub) {
      return [
        {
          collection: 'tasks',
          where: ['projectKey', '==', projectId],
          orderBy: [['updatedAt', 'desc']],
          storeAs: '_tasks'
        },
        {
          collection: 'tasks',
          where: ['idProject', '==', projectId],
          orderBy: [['updatedAt', 'desc']],
          storeAs: '_subtasks'
        },
        { collection: 'taskColumns', where: [['projectKey', '==', projectId]], storeAs: '_taskColumns' },
        { collection: 'columnOrder', where: [['projectKey', '==', projectId]], storeAs: '_columnOrder' }
      ];
    }
    return [
      {
        collection: 'tasks',
        where: ['projectKey', '==', projectId],
        orderBy: [['updatedAt', 'desc']],
        storeAs: '_tasks'
      },
      { collection: 'taskColumns', where: [['projectKey', '==', projectId]], storeAs: '_taskColumns' },
      { collection: 'columnOrder', where: [['projectKey', '==', projectId]], storeAs: '_columnOrder' }
    ];
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useFirestoreConnect(() => queryFilter);

  useEffect(() => {
    if (!isLoaded(gettasks) && !isLoaded(gettaskColumns) && !isLoaded(getcolumnOrder) && !isLoaded(getsubtasks)) {
      setLoading(true);
    } else {
      if (columnOrder?.length) {
        let _board = {
          columnOrder: columnOrder[0].columnOrder,
          columns: objFromArray(taskColumns),
          cards: objFromArray(tasks),
          cardsOnArray: tasks,
          columnsOnArray: taskColumns,
          columnOrderId: columnOrder[0]?.id
        };

        const diff = difference(_board, board);
        let changes = { ...kanban?.board };

        keys(diff)?.forEach((key) => {
          changes[key] = _board[key];
        });

        dispatch(getBoard(changes));
        setBoard(changes);
      }
      setLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettasks, gettaskColumns, getcolumnOrder, tasks, taskColumns, columnOrder, dispatch]);

  return { loading, board: getTaskBoard };
}

export function useTaskProjectNoListener({ currentProject, getSub = false, user }) {
  const [loading, setLoading] = useState(true);
  const [board, setBoard] = useState(initialState);

  const dispatch = useDispatch();

  const onResult = (result) => {
    const isManager = Boolean((currentProject?.managers || [])?.find((el) => el?.id === user?.id));
    let list = result?.cards;
    list = list
      ?.map((el) => ({ ...el, _visibility: el?.visibility || TASK_VISIBILITY.PUBLIC }))
      ?.filter((el) => isManager || el?._visibility === TASK_VISIBILITY.PUBLIC || el?.canAccessId?.includes(user?.id))
      ?.map(({ _visibility, ...rest }) => ({ ...rest }));

    let _board = {
      ...initialState,
      columnOrder: result?.columnOrder,
      columnOrderId: result?.columnOrderId,
      columns: objFromArray([...(result.columns || [])]),
      cards: objFromArray([...(list || [])]),
      cardsOnArray: [...(list || [])],
      columnsOnArray: [...(result.columns || [])]
    };

    //console.log({ board });

    if (!result?.subLoading && !result?.taskLoading && !result?.colLoading && !result?.colOrderLoading) {
      setBoard(_board);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      getBoardWithSub({
        projectId: currentProject?.id,
        isSub: getSub,
        onResult: onResult,
        onError: () => setLoading(false)
      })
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  return {
    loading,
    board
  };
}
