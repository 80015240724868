export const APPOINTMENT_TYPE = {
  Appointment: 'Appointment',
  MakingAppointment: 'MankingAppointment'
};

export const APPOINTMENT_TYPE_IVITE = 'Invite';

export const APPOINTMENT_TYPE_LABEL = {
  [APPOINTMENT_TYPE.Appointment]: 'Rendez-vous direct',
  [APPOINTMENT_TYPE.MakingAppointment]: 'Prise de rendez-vous'
};
