import { filter, orderBy, values as _values } from 'lodash';
import { toDateTime } from '../utils/unixDateTime';
import { gDate } from '../utils/formatTime';

export function dateConverter(due) {
  let start = due?.[0] || null;
  let end = due?.[1] || null;

  if (start?.seconds) {
    start = toDateTime(start.seconds).toString();
  }
  if (end?.seconds) {
    end = toDateTime(end.seconds).toString();
  }

  return [start, end];
}

export function diffDaysWithToDay(date) {
  const deb = date?.seconds ? toDateTime(date?.seconds) : new Date(date);
  const end = new Date();

  const diffTime = Math.abs(deb - end);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function diffDaysWithToHours(date) {
  const deb = date?.seconds ? toDateTime(date?.seconds) : new Date(date);
  const end = new Date();

  const diffTime = Math.abs(deb - end);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60));
  return diffDays;
}

const applyFilter = (tasks, filters) => {
  if (filters.projectName.trim() !== '') {
    tasks = filter(tasks, (_task) => _task.project.toUpperCase().includes(filters.projectName.toUpperCase()));
  }

  if (filters.createBy) {
    tasks = filter(tasks, (_task) => _task.createdBy.id === filters.createBy.id);
  }

  if (filters.assigne.length) {
    tasks = filter(tasks, (_task) => filters.assigne.some((one) => _task?.assignee.find((_as) => _as.id === one.id)));
  }

  if (filters.percent) {
    tasks = filter(tasks, (_project) => {
      if (filters.percent === '0,25') {
        return _project.progress >= 0 && _project.progress <= 25;
      }
      if (filters.percent === '25,50') {
        return _project.progress >= 25 && _project.progress <= 50;
      }
      if (filters.percent === '50,75') {
        return _project.progress >= 50 && _project.progress <= 75;
      }
      if (filters.percent === '75,100') {
        return _project.progress >= 75 && _project.progress <= 100;
      }

      return _project;
    });
  }

  if (filters.status) {
    tasks = filter(tasks, (_project) => _project.state === filters.status);
  }

  if (filters.progress) {
    if (filters.progress % 2 === 0) {
      return (tasks = orderBy(tasks, ['progress'], ['desc']));
    }
    tasks = orderBy(tasks, ['progress'], ['asc']);
  }

  return tasks;
};

const FILTER = {
  ALL: 'Tout',
  TWO_DAY: 'À 2 jours près',
  LATE: 'En retard'
};

const filterTaskByTime = (tasks = [], filter) => {
  switch (filter) {
    case FILTER.ALL:
      return tasks;
    case FILTER.TWO_DAY: {
      return tasks?.filter((one) => {
        const due = one?.due;
        const progress = one?.progress || 0;
        return one?.due[1] && progress < 100 && gDate(due[1]) > new Date() && diffDaysWithToDay(due[1]) <= 2;
      });
    }
    case FILTER.LATE: {
      return tasks?.filter((one) => {
        const progress = one?.progress || 0;
        return one?.due[1] && progress < 100 && gDate(one?.due[1]) < new Date();
      });
    }
    default:
      return tasks;
  }
};
