import React, { useEffect, useMemo, useState } from 'react';
import ActionModal from '../ActionModal';
import useOnlyOffice from 'src/hooks/useOnlyOffice';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'src/redux/store';
import { useParams } from 'react-router-dom';

/**
 *
 * @param {object} props
 * @param {string} props.docId
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {string} props.currentDocKey
 * @returns
 */
export default function DocumentOutDateVersion({ open, onClose }) {
  const { id, docKey } = useParams();
  const { loading } = useOnlyOffice(id);
  const { docKeys } = useSelector((state) => state.oky_driver);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNewDocKey = useMemo(() => docKeys[id]?.docKey && docKey !== docKeys[id]?.docKey, [docKeys]);

  const handleClose = () => {
    onClose(docKeys[id]?.docKey);
  };

  return (
    <ActionModal
      title="Info"
      color="info"
      open={open}
      onClose={onClose}
      removeCancel
      desc={
        loading || !getNewDocKey
          ? 'Le document est en cours de traitement. Veuillez patienter un instant.'
          : 'Taitement du document terminé. '
      }
      action={
        <LoadingButton loading={loading || !getNewDocKey} onClick={handleClose} color="info" variant="contained">
          Ouvrir
        </LoadingButton>
      }
    />
  );
}
