import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr as LocalFr } from 'date-fns/locale';
// @mui
import { Box } from '@mui/material';
import { gDate } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

DisplayTime.propTypes = {
  isSameDays: PropTypes.bool,
  isSameMonths: PropTypes.bool,
  onOpenPicker: PropTypes.func,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  sx: PropTypes.object,
  anchor: PropTypes.any
};

export default function DisplayTime({ startTime, endTime, isSameDays, isSameMonths, onOpenPicker, sx, anchor }) {
  const style = {
    typography: 'caption',
    cursor: 'pointer',
    '&:hover': { opacity: 0.72 }
  };

  if (!startTime && endTime) {
    return (
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }} ref={anchor}>
        {format(gDate(endTime), 'dd MMM', { locale: LocalFr })}
      </Box>
    );
  }

  if (isSameMonths) {
    return (
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }} ref={anchor}>
        {isSameDays
          ? format(gDate(endTime), 'dd MMM', { locale: LocalFr })
          : `${format(gDate(startTime), 'dd', { locale: LocalFr })} - ${format(gDate(endTime), 'dd MMM', {
            locale: LocalFr
          })}`}
      </Box>
    );
  }
  return (
    <Box onClick={onOpenPicker} sx={{ ...style, ...sx }} ref={anchor}>
      {!startTime && endTime ? format(gDate(endTime), 'dd MMM', { locale: LocalFr }) :
        `${startTime && format(gDate(startTime), 'dd MMM', { locale: LocalFr })} - ${endTime && format(gDate(endTime), 'dd MMM', { locale: LocalFr })}`}
    </Box>
  );
}