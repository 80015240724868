import { useState } from "react";
import { useToggleV2 } from "src/hooks/useToggle";

/**
 * A custom React hook that manages the state related to saving a document without a document.
 * 
 * The hook returns an object with the following properties:
 * - `_saveWithoutDocument`: a boolean state indicating whether to save without a document
 * - `_setSaveWithoutDocument`: a function to update the `_saveWithoutDocument` state
 * - `saveWithoutUpdate`: a boolean state indicating whether to display the "save without update" prompt
 * - `displaySaveWithoutUpdate`: a function to show the "save without update" prompt
 * - `hidesaveWithoutUpdate`: a function to hide the "save without update" prompt
 * - `saveWithoutDocument`: a boolean state indicating whether to display the "save without document" prompt
 * - `displaySaveWithoutDocument`: a function to show the "save without document" prompt
 * - `hideSaveWithoutDocument`: a function to hide the "save without document" prompt
 */
const useDocument = () => {
  const [_saveWithoutDocument, _setSaveWithoutDocument] = useState(false);
  const [saveWithoutUpdate, displaySaveWithoutUpdate, hidesaveWithoutUpdate] = useToggleV2();
  const [saveWithoutDocument, displaySaveWithoutDocument, hideSaveWithoutDocument] = useToggleV2();

  return { _saveWithoutDocument, _setSaveWithoutDocument, saveWithoutUpdate, displaySaveWithoutUpdate, hidesaveWithoutUpdate, saveWithoutDocument, displaySaveWithoutDocument, hideSaveWithoutDocument };
}

export default useDocument;