/**
 * @typedef {{
 *  id: string?
 *  name: string,
 *  isPrivate: boolean,
 *  description: string,
 *  color: string,
 *  background: string,
 *  canAccess: Array,
 *  canAccessId: Array,
 *  createBy: {avatar: string, name: string, email:string, id: string},
 *  createdAt: Date?,
 *  dataInterval: {end: Date?, start: Date?},
 *  interval: any,
 *  lastOpen: Date?,
 *  managers: Array,
 *  progress: number,
 *  state: string,
 *  updatedAt: Date?,
 * }}
 */
export var ProjectType = {
  name: '',
  description: '',
  isPrivate: false,
  color: '',
  background: '',
  canAccess: [],
  canAccessId: [],
  createBy: { avatar: '', name: '', email: '', id: '' },
  createdAt: null,
  dataInterval: { end: null, start: null },
  interval: null,
  lastOpen: null,
  managers: [],
  progress: 0,
  state: 'open',
  updatedAt: null
};
