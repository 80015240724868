import { useState, useEffect } from 'react';
import { getOnlyOfficeFileCopy } from 'src/redux/slices/driver/driver';
import { dispatch } from 'src/redux/store';

/**
 *
 * @param {string} docId
 * @returns {{
 *  loading: boolean,
 *  doc: {
 *    name: string,
 *    isEditing: boolean,
 *    docKey: string,
 *    id: string,
 *    url: string,
 *    doc_path: string,
 *    parentId: string,
 *    module: string
 *   }
 * }}
 */
export default function useOnlyOffice(docId) {
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState(null);
  const [init, setInit] = useState(null);

  useEffect(() => {
    if (docId && init !== docId) {
      dispatch(
        getOnlyOfficeFileCopy({
          id: docId,
          callback: (data) => {
            setDoc(data);
            setLoading(false);
            setInit(docId);
          },
          onError: () => setLoading(false)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId]);

  return { doc, loading };
}
