import 'video-react/dist/video-react.css';
import React, { useEffect, useMemo } from 'react';
import { Player, BigPlayButton } from 'video-react';
import CIconButton from './CIconButton';
import { Dialog, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const size = {
  HEIGHT: 480,
  WIDTH: '100%'
};

export default function VideoAndAudioPlayerModal({ url, type, fileType, name, open, onClose }) {
  const playerRef = React.useRef(null);
  const [fullscreen, setFullScreen] = React.useState(false);
  const playerState = playerRef.current?.getState()?.player || null;

  const toogleScreen = () => {
    setFullScreen(!fullscreen);
    playerRef.current?.toggleFullscreen();
  };

  useEffect(() => setFullScreen(playerState?.isFullscreen), [playerState?.isFullscreen]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ zIndex: (t) => t.zIndex.tooltip + 20 }}>
      <Stack width={1} height={1} position="relative">
        <Player
          ref={playerRef}
          playsInline
          {...(type?.includes('audio') && { poster: '/static/icons/file/audio_poster.jpg' })}
          src={url}
          fluid={false}
          height={size.HEIGHT}
          width={size.WIDTH}
        >
          <BigPlayButton position="center" />
        </Player>

        <Stack
          p={0.3}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            top: 1,
            right: 3,
            borderRadius: 1,
            position: 'absolute',
            backgroundColor: 'transparent'
          }}
        >
          <CIconButton
            noBorder
            title={fullscreen ? 'Réduire' : 'Agrandir'}
            size="small"
            onClick={toogleScreen}
            sx={{ p: 0.2, bgcolor: 'transparent' }}
          >
            {!fullscreen ? (
              <FullscreenIcon fontSize="small" sx={{ color: '#FFF' }} />
            ) : (
              <MinimizeIcon fontSize="small" sx={{ color: '#FFF' }} />
            )}
          </CIconButton>

          <CIconButton noBorder title="Fermer" size="small" onClick={onClose} sx={{ p: 0.2, bgcolor: 'transparent' }}>
            <CloseIcon fontSize="medium" sx={{ color: '#FFF' }} />
          </CIconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
