export const baseColors = {
  RED: '#ff9191',
  REDD: '#E01E5A',
  BLUE: '#7299ff',
  BLEU_N: '#1264A3',
  BLUE_GREEN: '#25737d',
  BLUEDARK: '#3f50b5',
  BLUELIGHT: '#AAC6FF',
  GREEN: '#25c764',
  GREEN_N: '#74B810',
  GREENDARK: '#095E0C',
  GREENLIGHT: '#79EE8F',
  GREY: '#afafaf',
  GREYC: '#2e2d2f',
  GREYLIGHT: '#E7E7E7',
  GREYLIGHTER: '#F9F9F9',
  BLACK: '#232323',
  WARINIG: '#FCB211'
};

export const themeColor = {
  CARROT_ORANGE: '#ef9008',
  SMOKY_BLACK: '#121212',
  GAINSBORO: '#E0E0E0',
  RICH_BLACK: '#050505'
};
