import React from 'react';
import { Backdrop, Stack, Typography } from '@mui/material';
import { CircularProgress } from '@mui/joy';

/**
 * Renders a loading screen dialog with a linear progress indicator.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines whether the dialog should be open or not.
 * @returns {JSX.Element} The loading screen dialog.
 */
const TaskLoadingSreen = ({ open }) => {
  return (
    <Backdrop open={open}>
      <Stack
        px={5}
        py={3}
        spacing={1}
        direction="row"
        bgcolor="#fff"
        borderRadius={1}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size="sm" />
        <Typography>Chargement en cours... </Typography>
      </Stack>
    </Backdrop>
  );
};

export default TaskLoadingSreen;
