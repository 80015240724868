import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Avatar, Box, Typography, Stack, alpha } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { fr as LocalFr } from 'date-fns/locale';
import AttachmentItem from "./AttachmentItem";
import useToggle from "../../../hooks/useToggle";
import LightboxModal from "../../../components/LightboxModal";
import { useMemo, useState } from "react";
import { Icon } from '@iconify/react';
import checkLg from '@iconify/icons-bi/check-lg';
import checkAll from '@iconify/icons-bi/check-all';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 320,
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary
}));

const MessageImgStyle = styled('img')(({ theme }) => ({
    height: 200,
    minWidth: 296,
    width: '100%',
    cursor: 'pointer',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius
}));


export const MessageContainer = ({ children, isMe, sx }) => {
    return (
        <ContentStyle sx={{
            color: 'grey.600',
            fontSize: 14,
            ...(isMe && {
                bgcolor: (t) => alpha(t.palette.primary.light, 0.1)
            })
            , ...sx
        }}
        >
            {children}
        </ContentStyle>
    )
}

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
    message: PropTypes.object.isRequired,
    conversation: PropTypes.object.isRequired,
    // onOpenLightbox: PropTypes.func
};

export default function ChatMessageItem({
    message,
    conversation,
    openReadingState,
    // onOpenLightbox,
    ...other
}) {
    const { user: { id } } = useAuth();
    const { open, handleClose, handleOpen } = useToggle()
    const [selectedImage, setSelectedImage] = useState(null);

    const images = useMemo(() => {
        const urls = message.attachments.filter(file => file.type.includes('image')).map(file => file.url)
        return urls || []
    }, [message])


    const sender = conversation.participants.find(
        (participant) => participant.id === message.senderId
    );

    const details = { avatar: sender?.avatar || '', name: sender?.name || '' }

    const senderDetails =
        (message.senderId === id)
            ? { type: 'moi', ...details }
            : details;

    const isMe = senderDetails.type === 'moi';
    const displayName = senderDetails?.name && senderDetails?.name;

    const hasAllParticipantRead = useMemo(() => {
        const participants = conversation?.participants || []
        const hasReadIds = message?.hasReadIds || []

        let flag = true;

        participants?.forEach(person => {
            if (!hasReadIds?.includes(person?.id)) {
                flag = false
            }
        })

        return flag

    }, [conversation?.participants, message?.hasReadIds])




    const handleReadingState = () => openReadingState && openReadingState(message?.id)

    const handleSelection = (url) => {
        setSelectedImage(images.findIndex(one => one === url))
        handleOpen()
    }


    return (
        <RootStyle {...other}>
            <Box
                sx={{
                    display: 'flex',
                    ...(isMe && {
                        ml: 'auto'
                    })
                }}
            >
                {senderDetails.type !== 'me' && (
                    <Avatar
                        alt={senderDetails?.name || ''}
                        src={senderDetails?.avatar || ''}
                        sx={{ width: 32, height: 32 }}
                    />
                )}

                <Box sx={{ ml: 2 }}>
                    <InfoStyle
                        noWrap
                        variant="caption"
                        sx={{ ...(isMe && { justifyContent: 'flex-end' }) }}
                    >
                        {!isMe && `${displayName},`}&nbsp;
                        {formatDistanceToNowStrict(message.createdAt || new Date(), {
                            addSuffix: true,
                            locale: LocalFr
                        })}
                    </InfoStyle>


                    {
                        message && message.attachments.map(file => {
                            const isImage = file.type.includes('image')

                            return (
                                <MessageContainer isMe={isMe} key={file.url}>
                                    {
                                        isImage && (
                                            <MessageImgStyle
                                                alt="attachment"
                                                src={file.url}
                                                onClick={() => handleSelection(file.url)}
                                            />
                                        )
                                    }
                                    {
                                        !isImage && (
                                            <AttachmentItem createdAt={message.createdAt} file={file} />
                                        )
                                    }
                                </MessageContainer>
                            )
                        })
                    }

                    {
                        message.body.length > 0 && (
                            <MessageContainer isMe={isMe}>
                                <Stack spacing={2}>

                                    <Typography sx={{ whiteSpace: "pre-line" }} variant="body2">
                                        {message.body}
                                    </Typography>

                                    {
                                        isMe && (
                                            <Stack direction={'row'} justifyContent={'end'}>
                                                <Icon
                                                    icon={hasAllParticipantRead ? checkAll : checkLg}
                                                    onClick={handleReadingState}
                                                    color={hasAllParticipantRead ? 'blue' : 'default'}
                                                />
                                            </Stack>
                                        )
                                    }


                                </Stack>
                            </MessageContainer>
                        )
                    }

                </Box>

            </Box>

            <LightboxModal
                images={images}
                photoIndex={selectedImage}
                setPhotoIndex={setSelectedImage}
                isOpen={open}
                onClose={handleClose}
            />

        </RootStyle>
    );
}
