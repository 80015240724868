import { AttachementType } from './Attachment_type';

/**
 * @typedef {{
 *  version: string,
 *  courriel_type: string,
 *  courriel_reference: string,
 *  courriel_date: Date?,
 *  courriel_object: string,
 *  correspondant: { title:string, contact: string, email: string},
 *  save_reference:string,
 *  save_date:Date?,
 *  operation_type: string,
 *  isPriority:boolean,
 *  priority: string,
 *  annotation: string[],
 *  due: Array<{label: string, assigneBy: string, assigneTo: string, date: Date | null}>,
 *  rappel: {courriel_id: string, date: Date?} ,
 *  attachments: { received: Array<AttachementType>, send: Array<AttachementType>},
 *  completed: boolean,
 *  completedBy: string | null,
 *  isDelete: boolean,
 *  isArchived: boolean,
 *  validation: {state: boolean, by: string | null, isReject: boolean, rejectNote: {by: string, note: string, at: Date}[]},
 *  toDoIds: Array<string>,
 *  toReviewIds: Array<string>,
 *  historyIds: Array<string>,
 *  archiveIds: Array<string>,
 *  trashedIds: Array<string>,
 *  toDoIdsTemp : Array<string>,
 *  historyIdsTemp,
 *  toReviewIdsTemp,
 *  trashedIdsTemp,
 *  archiveIdsTemp,
 *  historyIdsArchived,
 *  toDoIdsArchived,
 *  toReviewIdsArchived,
 *  trashedIdsArchived,
 *  archiveIdsArchived,
 *  createdAt: Date | null,
 *  createdBy: string,
 *  updated: {by: string, at: Date | null},
 *  notes : string ,
 *  defaultReceiver:string,
 *  coReceiver: Array<string>,
 *  assignationOrganigramme: Array<{label:string, users: Array}>,
 *
 * }}
 */
export let Affectation_v4_Type;

/**
 * @type {Affectation_v4_Type}
 */
export let Affectation_v4_model = {
  version: 'v4',
  courriel_type: '',
  courriel_reference: '',
  courriel_date: null,
  courriel_object: '',
  correspondant: { title: '', contact: '' ,email:''},
  save_reference: '',
  save_date: null,
  operation_type: '',
  isPriority: false,
  priority: '',
  annotation: [],
  due: [],
  rappel: { courriel_id: '', date: null },
  attachments: { received: [], send: [] },
  completed: false,
  isDelete: false,
  isArchived: false,
  validation: { state: false, by: null, isReject: false, rejectNote: [] },
  toDoIds: [],
  toReviewIds: [],
  historyIds: [],
  archiveIds: [],
  trashedIds: [],
  toDoIdsTemp: [],
  historyIdsTemp: [],
  toReviewIdsTemp: [],
  trashedIdsTemp: [],
  archiveIdsTemp: [],
  historyIdsArchived: [],
  toReviewIdsArchived: [],
  trashedIdsArchived: [],
  archiveIdsArchived: [],
  createdAt: null,
  createdBy: null,
  updated: { by: null, at: null },
  notes:'',
  defaultReceiver: null,
  coReceiver: [],
  assignationOrganigramme: []
};

/**
 * @typedef {{
 * organigramme:Array<{label:string, users: Array}>,
 * higHLevelID:string?,
 * defaultRecevierID:string?,
 * canCreateIds: Array<string>,
 * printerIDs:Array<string>,
 * deleteIDs: Array<string>,
 * archiveIDs : Array<string>,
 * reporterIDs: Array<string>,
 * createdAt: Date?,
 * updated: {by:string, at: Date?}
 * }}
 */
export let Affectation_Setting_Type;

/**
 * @type {Affectation_Setting_Type}
 */
export let Affectation_Setting_model = {
  organigramme: [],
  higHLevelID: null,
  defaultRecevierID: null,
  canCreateIds: [],
  printerIDs: [],
  deleteIDs: [],
  archiveIDs: [],
  reporterIDs: [],
  createdAt: null,
  updated: null
};
