export default class Timer {
  static repeat(duration, callback) {
    let subscriptionKey = null;

    subscriptionKey = setInterval(callback, duration);

    return () => clearInterval(subscriptionKey);
  }
}

export function timerDisplay(sec) {
  if (sec < 60) {
    if (sec < 10) return `00:0${sec}`;
    return `00:${sec}`;
  }

  let ctime = sec / 60;
  let entertime = Number(Number(ctime).toFixed(0));

  return `${entertime < 10 ? '0' + entertime : entertime}:${sec - entertime * 60}`;
}
