
import React, { useState } from 'react';
import { Stack } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import { serverTime } from 'src/utils/serverTime';
import { dispatch } from 'src/redux/store';
import { SaveTaskDocs, multipleFilesSave } from 'src/redux/slices/document';
import CustomFileView from './CustomFileView';


/**
 *
 * @param {{affectation: Affectation_v4_Type, user: firebase.default.UserInfo, onUpdate: function(string, any, function): void, disabled: boolean, setAttachement: function,setAttachement: function,attachement: any, loadingAttachement: boolean, setLoadingAttachement: function, defaultOpenLightbox: boolean}}
 * @returns
 */
const AffectationNoteFileView = ({ affectation, onUpdate, disabled = false, setAttachement: setFiles, attachement: files, loadingAttachement, setLoadingAttachement, defaultOpenLightbox = false }) => {
  const { user } = useAuth();
  const { email, displayName, uid: id } = user;

  const [isUploading, setUploading] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);

  const onRemoveFile = (file) => {
    const rest = files.filter((att) => {
      if (att?.id) {
        return att.id !== file.id;
      }
      return att !== file;
    });
    setFiles([...rest]);
  };

  const onUpdateFile = (oldFile, newFile) => {
    const rest = files.map((att) => {
      if (oldFile?.id) {
        if (att?.id === oldFile?.id) {
          return newFile;
        }
        return att;
      }

      if (att === oldFile) {
        return newFile;
      }
      return att;
    });
    setFiles([...rest]);
  };

  const handleUploadAttachement = (_files) => {
    setUploading(true);

    const handleFiles = (newfiles) => {
      const user_to_files = newfiles.map((_file) => {
        return {
          ..._file,
          email,
          userId: id,
          id: _file?.id || uuidv4(),
          displayName,
          createAt: serverTime()
        };
      });

      setUploading(false);

      let newAttachs = files.map((_att) => {
        if (!_att?.id) {
          return { ..._att, id: uuidv4() };
        }
        return _att;
      });
      let attach = [...newAttachs, ...user_to_files];
      setFiles(attach);
      //dispatch(onUpdate(affectation, { attachments: [...attach] }, () => { }));
      dispatch(SaveTaskDocs(newfiles));
    };
    multipleFilesSave(_files, handleFiles, null, setUploadingFiles);
  };

  //console.log('tasks',taskRights);

  return (
    <Stack width={1}>
      <CustomFileView
        attachments={files}
        isUploading={isUploading || loadingAttachement}
        onRemove={disabled ? null : onRemoveFile}
        onUpdateFile={disabled ? null : onUpdateFile}
        hideDelete={true}
        hideAdd={disabled}
        onChange={disabled ? null : handleUploadAttachement}
        disabled={disabled}
        defaultOpenLightbox={defaultOpenLightbox}
      />
    </Stack>
  );
}

export default AffectationNoteFileView;