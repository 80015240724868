import { styled, Typography } from '@mui/material';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 120,
  fontSize: 14,
  flexShrink: 0,
  color: theme.palette.text.primary
}));

export default LabelStyle;
