import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import useLogo from 'src/hooks/useLogo';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  defaultSrc: PropTypes.string
};
const primarySrc =
  'https://firebasestorage.googleapis.com/v0/b/okydook-e2a3b.appspot.com/o/-41LZVYG2Svm65uI8XCcA6PiuVZO90%2Fdefault.jpg?alt=media&token=ead1d3f0-61ca-4c59-b808-9b82c8ffe841';

/**
 *
 * @param {{sx: import('@mui/material').SxProps, defaultSrc:string}} propss
 * @returns
 */
export default function Logo({ sx, defaultSrc = null }) {
  const { logo_url } = useLogo();

  return <Box component="img" alt="logo" src={defaultSrc || logo_url || primarySrc} {...sx} />;
}
