import he from 'he';

/**
 * Formats a message by replacing base64-encoded image data with HTML `<img>` tags.
 *
 * @param {string} message - The message to format.
 * @returns {string} The formatted message with image tags.
 */
export const formatHTML = (message) => {
  if (!message) return '';
  // find and replace base64  [data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2w] by <img src="cid:image" />
  const imgRegex = /\[(data:image\/\w+;base64,[A-Za-z0-9+/]+={0,2})\]/g;
  const formattedMessage = message.replace(imgRegex, '<img src="$1" />');
  return he.decode(formattedMessage);
};
