import React from 'react';
import { Icon } from '@iconify/react';
import { Box, MenuItem, Typography, Tooltip } from '@mui/material';
import { useToggleV2 } from 'src/hooks/useToggle';

export const MenuLine = ({
  icon,
  title,
  color,
  onClick,
  altIcon,
  iconColor,
  hide = false,
  divider = false,
  fontSize = null,
  iconSize = null,
  fontWeight = 400,
  disabled = false,
  loading = false,
  hoverAction = false,
  variant = 'caption',
  disabledColor = 'grey',
  tooltipTitle = 'cette action est désactivée'
}) => {
  const [hover, onHover, endHover] = useToggleV2();
  if (hide) return null;
  return (
    <Tooltip title={disabled ? tooltipTitle : ''} placement="right">
      <MenuItem
        divider={divider}
        disabled={disabled || loading}
        onClick={disabled ? undefined : onClick}
        sx={{
          py: 1,
          px: 1.5,
          '&:hover': disabled ? {} : { backgroundColor: 'rgba(0, 0, 0, 0.04)' } // Désactiver le hover si le menu est désactivé
        }}
        onMouseEnter={onHover}
        onMouseLeave={endHover}
      >
        <Box
          {...((iconColor || color) && { color: disabled ? disabledColor : iconColor || color })}
          component={Icon}
          icon={icon}
          sx={{ width: 15, height: 15, flexShrink: 0, mr: 1, ...iconSize }}
        />

        <Typography
          variant={variant}
          fontWeight={fontWeight}
          {...(fontSize && { fontSize: fontSize })}
          display={!hoverAction ? 'block' : hover ? 'block' : 'none'}
          {...(color && { color: disabled ? disabledColor : color })}
        >
          {title}
        </Typography>

        <Box
          {...(altIcon && { color: disabled ? disabledColor : iconColor || color })}
          component={Icon}
          icon={altIcon}
          sx={{ width: 15, height: 15, flexShrink: 0, ml: 'auto', ...iconSize }}
        />
        {loading && (
          <Box
            component={Icon}
            icon="eos-icons:loading"
            sx={{ width: 15, height: 15, flexShrink: 0, ml: 'auto', ...iconSize }}
          />
        )}
      </MenuItem>
    </Tooltip>
  );
};
