import {
  FORMAT_EXCEL,
  FORMAT_HTML,
  FORMAT_ILLUSTRATOR,
  FORMAT_IMG,
  FORMAT_PDF,
  FORMAT_PHOTOSHOP,
  FORMAT_POWERPOINT,
  FORMAT_TXT,
  FORMAT_VIDEO,
  FORMAT_WORD,
  FORMAT_AUDIO
} from 'src/utils/getFileFormat';

export const getFilename = (url = '') => {
  const removeSlash = url.substring(url.lastIndexOf('/') + 1);
  const removeQuery = removeSlash.includes('?') ? removeSlash.substring(0, removeSlash.lastIndexOf('?')) : removeSlash;
  return removeQuery;
};

function getFileType(fileUrl = '') {
  const filename = getFilename(fileUrl);
  return filename?.split('.').pop();
}

export function onlyOfficeFileType(filename = '') {
  return filename?.split('.').pop();
}

export function onlyOfficeDocumentType(fileUrl = '') {
  let format;
  switch (fileUrl?.includes(getFileType(fileUrl))) {
    case FORMAT_IMG.includes(getFileType(fileUrl)):
      format = 'image';
      break;
    case FORMAT_VIDEO.includes(getFileType(fileUrl)):
      format = 'video';
      break;
    case FORMAT_AUDIO.includes(getFileType(fileUrl)):
      format = 'audio';
      break;
    case fileUrl?.includes('word'):
      format = 'word';
      break;
    case FORMAT_WORD.includes(getFileType(fileUrl)):
      format = 'word';
      break;
    case fileUrl?.includes('text/plain'):
      format = 'word';
      break;
    case fileUrl?.includes('html'):
      format = 'word';
      break;
    case FORMAT_TXT.includes(getFileType(fileUrl)):
      format = 'word';
      break;
    case FORMAT_HTML.includes(getFileType(fileUrl)):
      format = 'word';
      break;
    case fileUrl?.includes('excel'):
      format = 'cell';
      break;
    case FORMAT_EXCEL.includes(getFileType(fileUrl)):
      format = 'cell';
      break;
    case fileUrl?.includes('powerpoint'):
      format = 'slide';
      break;
    case FORMAT_POWERPOINT.includes(getFileType(fileUrl)):
      format = 'slide';
      break;
    case FORMAT_PDF.includes(getFileType(fileUrl)):
      format = 'word';
      break;
    case FORMAT_PHOTOSHOP.includes(getFileType(fileUrl)):
      format = 'photoshop';
      break;
    case FORMAT_ILLUSTRATOR.includes(getFileType(fileUrl)):
      format = 'illustrator';
      break;
    default:
      format = 'word';
  }

  return format;
}
