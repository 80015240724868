import { IconButton, Sheet } from '@mui/joy';
import { Box, Typography, Stack, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { Fragment, useState } from 'react';
import ActionModal from 'src/components/ActionModal';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import { Affectation_v4_Type } from 'src/models/Affectation_v4_type';

import { dispatch } from 'src/redux/store';

import { v4 as uuidv4 } from 'uuid';
import { multipleFilesSave, SaveTaskDocs } from 'src/redux/slices/document';
import CustomFileView from 'src/components/CustomFileView';
import { serverTime } from 'src/utils/serverTime';
import AffectationNoteFileView from './AffectationNoteFileView';
import TaskFileView from 'src/section/tasks/components/TaskFileView';
import TaskFileViewNew from 'src/section/tasks/components/TaskFileViewNew';
import InputLine from './CustomInputLine';
import AttachSendSvg from './AttachSendSvg';
import AttachReceivedSvg from './AttachReceivedSvg';
import Scrollbar from './Scrollbar';
import { useSnackbar } from 'notistack';

/**
 *
 * @param {{affectation: Affectation_v4_Type, user: firebase.default.UserInfo, onUpdate: function(string, any, function): void, disabled: boolean, setAttachement: function,setAttachement: function,attachement: any, loadingAttachement: boolean, setLoadingAttachement: function, defaultOpenLightbox: boolean}}
 * @returns
 */
export const NoteFileView = ({
  disabled = false,
  setAttachement: setFiles,
  attachement: files,
  loadingAttachement,
  defaultOpenLightbox = false
}) => {
  const { user } = useAuth();
  const { email, displayName, uid: id } = user;

  const [isUploading, setUploading] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);

  const onRemoveFile = (file) => {
    const rest = files.filter((att) => {
      if (att?.id) {
        return att.id !== file.id;
      }
      return att !== file;
    });
    setFiles([...rest]);
  };

  const onUpdateFile = (oldFile, newFile) => {
    const rest = files.map((att) => {
      if (oldFile?.id) {
        if (att?.id === oldFile?.id) {
          return newFile;
        }
        return att;
      }

      if (att === oldFile) {
        return newFile;
      }
      return att;
    });
    setFiles([...rest]);
  };

  const handleUploadAttachement = (_files) => {
    setUploading(true);

    const handleFiles = (newfiles) => {
      const user_to_files = newfiles.map((_file) => {
        return {
          ..._file,
          email,
          userId: id,
          id: _file?.id || uuidv4(),
          displayName,
          createAt: serverTime()
        };
      });

      setUploading(false);

      let newAttachs = files.map((_att) => {
        if (!_att?.id) {
          return { ..._att, id: uuidv4() };
        }
        return _att;
      });
      let attach = [...newAttachs, ...user_to_files];
      setFiles(attach);
      //dispatch(onUpdate(affectation, { attachments: [...attach] }, () => { }));
      dispatch(SaveTaskDocs(newfiles));
    };
    multipleFilesSave(_files, handleFiles, null, setUploadingFiles);
  };

  return (
    <Stack width={1}>
      <CustomFileView
        attachments={files}
        isUploading={isUploading || loadingAttachement}
        onRemove={disabled ? null : onRemoveFile}
        onUpdateFile={disabled ? null : onUpdateFile}
        hideDelete={true}
        hideAdd={disabled}
        onChange={disabled ? null : handleUploadAttachement}
        disabled={disabled}
        defaultOpenLightbox={defaultOpenLightbox}
      />
    </Stack>
  );
};

/**
 *
 * @param {{
 *  open: boolean,
 *  addFile: boolean,
 *  onClose: () =>{},
 *  onReject: (note:string, attachement?: Array) =>{},
 * }} props
 * @returns
 */
export const RejectMessage = ({ open, onClose, onReject }) => {
  const [value, setValue] = useState('');
  const { user } = useAuth();
  const [attachement, setAttachement] = useState([]);
  const [loadingAttachement, setLoadingAttachement] = useState(false);
  const { email, displayName, uid: id } = user;
  const [isUploading, setUploading] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);

  const handleUploadAttachement = (_files) => {
    setUploading(true);

    const handleFiles = (newfiles) => {
      const user_to_files = newfiles.map((_file) => {
        return {
          ..._file,
          email,
          userId: id,
          id: _file?.id || uuidv4(),
          displayName,
          createAt: serverTime()
        };
      });

      // setUploading(false);

      let newAttachs = attachement.map((_att) => {
        if (!_att?.id) {
          return { ..._att, id: uuidv4() };
        }
        return _att;
      });
      let attach = [...newAttachs, ...user_to_files];
      setAttachement(attach);
      dispatch(SaveTaskDocs(newfiles));
    };
    multipleFilesSave(
      _files,
      handleFiles,
      (data) => {
        console.log(data)
        setUploading(false);
        onReject(value, data);
        onClose();
      },
      setUploadingFiles
    );
  };

  const handleSendNote = () => {
    handleUploadAttachement(attachement);
    //onReject(value, attachement);
    // onClose();
  };

  const handleSendWithoutNote = () => {
    onReject();
    onClose();
  };

  return (
    <ActionModal
      title="Motif de rejet"
      color="error"
      open={Boolean(open)}
      onClose={onClose}
      moreAction={
        <Stack width={1}>
          <TextField
            color="info"
            fullWidth
            multiline
            minRows={4}
            disabled={isUploading}
            label="Ajouter un commentaire"
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
          <Stack width={1} direction='row' spacing={1} alignItems="center" minHeight={100} sx={{ border: '1px dashed #ccc', borderRadius: 1, padding: 1 }} my={1}>
            <Sheet
              variant="soft"
              sx={{
                border: '1px dashed #ccc',
                height: 100,
                width: 100,
                minWidth: 100,
                cursor: '',
                overflow: 'hidden',
                borderRadius: 'sm',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Stack alignItems="center">
                <InputLine
                  type="file"
                  value={attachement}
                  disabled={isUploading}
                  onChange={(field, value) => {
                    setAttachement(value);

                  }
                  }
                  fileView={
                    <Stack width={1} alignItems="center">
                      {isUploading ?
                        <Box>
                          <CircularProgress />
                        </Box>

                        :
                        <Fragment>
                          <AttachReceivedSvg />
                          <Typography color="info.main" fontSize={10} fontWeight="bold">
                            Ajouter un fichier
                          </Typography>
                        </Fragment>}
                    </Stack>
                  }
                />
              </Stack>
            </Sheet>
            <Stack direction="row" spacing={1} alignItems="center">
              <Scrollbar style={{ width: 400 }}>
                <CustomFileView
                  attachments={attachement}
                  isUploading={isUploading || loadingAttachement}
                  onRemove={(attachment) => {
                    setAttachement(attachement.filter((att) => att.id !== attachment.id));

                  }}
                  onUpdateFile={null}
                  hideDelete={true}
                  hideAdd={true}
                  canDeleteBlob
                  wrapLength={3}
                  disabledWrap={true}
                  onChange={null}
                  disabled={isUploading}
                  editable={true}
                />
              </Scrollbar>
            </Stack>
          </Stack>
        </Stack >
      }
      action={
        <Stack direction="row" spacing={3} width={1} justifyContent="flex-end" >
          <IconButton color="neutral" variant='outlined' onClick={handleSendWithoutNote} disabled={isUploading} sx={{ mx: 1 }}>
            <Typography sx={{ ml: 1 }}>
              Envoyer sans note
            </Typography>
            <Iconify icon="akar-icons:send" sx={{ mx: 1 }} />
          </IconButton>
          <IconButton color="success" variant='solid' type="submit" onClick={handleSendNote} disabled={isUploading} sx={{ mx: 1 }}>
            <Typography sx={{ ml: 1 }}>
              Envoyer
            </Typography>
            <Iconify icon="akar-icons:send" sx={{ mx: 1 }} />
          </IconButton>
        </ Stack>
      }
    />
  );
};
