import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import React from 'react';
import Iconify from 'src/components/Iconify';

/**
 * Renders a modal dialog with a title, message, and action buttons.
 *
 * @param {Object} props - The properties for the modal.
 * @param {boolean} props.open - Whether the modal is open or not.
 * @param {function} props.onClose - The function to call when the modal is closed.
 * @param {function} props.action - The function to call when the action button is clicked.
 * @param {string} [props.title] - The title of the modal. Defaults to 'Alert'.
 * @param {string} props.actionTitle - The text for the action button.
 * @param {string} props.message - The message to display in the modal.
 * @param {ReactNode} [props.children] - The content to display in the modal. If provided, overrides the message.
 * @param {boolean} [props.disabledAction=false] - Whether the action button is disabled.
 * @param {Object} [props.sx={}] - The style object for the modal.
 * @param {boolean} [props.isLoading=false] - Whether the modal is in a loading state.
 * @return {JSX.Element} The rendered modal dialog.
 */
export default function ChannelActionModal({ open, onClose, action, title, actionTitle, message, children, disabledAction, sx = {}, isLoading = false }) {

  return (
    <Dialog open={open} onClose={onClose} sx={sx}>
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between" mb={3}>
          <Stack>
            <Typography fontWeight="bold" variant="h5">
              {title || 'Alert'}
            </Typography>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {
          children ? children :
            <DialogContentText whiteSpace="pre-line" textAlign="center">
              {message}
            </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Annuler
        </Button>
        <LoadingButton variant="contained" color="error" onClick={action} disabled={disabledAction} loading={isLoading}>
          {actionTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
