import { useEffect, useRef } from 'react';
import { Fab, Box, useTheme, Stack, Badge } from '@mui/material';
import { Icon } from '@iconify/react';
import useToggle from 'src/hooks/useToggle';
import ConverseProvider, { useConverseProvider } from './ConverseProvider';
import ConverseModal from './ConverseModal';
import useConversation from '../../hooks/useConversation';
import { useNotifSnackbarContext } from 'src/App';

export function Converse() {
  const ref = useRef();
  const theme = useTheme();

  const { unreadCount, unreadIds } = useConversation();
  const { setCanSendChatNotif } = useNotifSnackbarContext();
  const { isChatVisible, switchChatVisibility, hideChat } = useConverseProvider();

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCanSendChatNotif(!isChatVisible), [isChatVisible]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          zIndex: 3001,
          bottom: 70,
          right: 10
        }}
      >
        <Stack direction="row-reverse" spacing={1}>
          <Fab
            color={isChatVisible ? 'inherit' : 'primary'}
            aria-label="add"
            onClick={switchChatVisibility}
            sx={{ mr: 2, ...(isChatVisible && { bgcolor: '#FFF', '&:hover': { bgcolor: '#FFF' } }) }}
            size="large"
          >
            {isChatVisible ? (
              <Icon icon="eva:close-fill" color={theme.palette.primary.main} height={25} />
            ) : (
              <Icon icon="bi:chat-left-fill" height={20} color="#FFF" />
            )}
          </Fab>

          <Badge
            badgeContent={unreadCount}
            invisible={unreadCount === 0}
            color={'error'}
            sx={{
              position: 'relative',
              left: 50,
              mr: 2,
              zIndex: (t) => t.zIndex.fab
            }}
          >
            <Box />
          </Badge>
          <Box ref={ref} />
        </Stack>
      </Box>

      {isChatVisible && <ConverseModal open={isChatVisible} anchor={ref} onClose={hideChat} />}
    </>
  );
}
